@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1 {
		@apply text-4xl font-bold;
	}
	h2 {
		@apply text-3xl font-bold;
	}
	h3 {
		@apply text-2xl font-bold;
	}
	h4 {
		@apply text-xl font-bold;
	}
	h5 {
		@apply text-lg font-bold;
	}

	.card {
		@apply border-2 border-slate-400 rounded-xl p-4 flex flex-col h-min bg-slate-700;
	}

	button {
		@apply w-32 h-12 rounded-lg disabled:brightness-75 hover:brightness-110 transition-all duration-200;
	}
}

body {
	@apply bg-slate-800 text-slate-200 select-none;

	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
